'use client';
import { useEffect } from 'react';
import asyncLoad from '../../common/load';
import { mediaQueries } from '@/common/styleguide';

let galleryOpen = false;

export type AdsConfig = {
  adType: 'ta' | 'list';
  effectDependencies?: any[];
};

export function destroyAdsGallery() {
  galleryOpen = true;
  window.adHandler.cmd.push(function () {
    window.adHandler.service.destroy();
  });
  try {
    document.getElementById('dspx_scroller').innerHTML = '';
  } catch (e) {}
}

export function requestAdsGallery() {
  galleryOpen = false;
  window.adHandler.cmd.push(function () {
    window.adHandler.service.request();
  });
}

type Props = {
  tags?: string[];
  articleId?: string;
  excludeCMPBanner?: boolean;
  adsConfig?: AdsConfig;
  excludeAds?: boolean;
  stickyAd?: 'textArticle' | 'listArticle' | 'none';
};

if (typeof window !== 'undefined') {
  const isDesktopMatchMedia = window.matchMedia(
    `(${mediaQueries.minWidthDesktop})`,
  );

  const onChangeDesktopMedia = () => {
    if (galleryOpen) {
      return;
    }
    window.adHandler.cmd.push(function () {
      window.adHandler.service.destroy();
    });
    try {
      document.getElementById('dspx_scroller').innerHTML = '';
    } catch (e) {}
    window.adHandler.cmd.push(function () {
      window.adHandler.service.request();
    });
  };
  isDesktopMatchMedia.onchange = onChangeDesktopMedia;
}

var mobileinterstitialEnabled = false;
var defaultDependencies = [];

export function AdHandlerService(props: Props) {
  const { tags, articleId, excludeCMPBanner, excludeAds, stickyAd } = props;
  const adType = props.adsConfig?.adType;
  const effectDependencies =
    props.adsConfig?.effectDependencies || defaultDependencies;

  useEffect(() => {
    window.pfSessionPI++;

    window.adHandler.cmd.push(function () {
      window.adHandler.service.destroyPageType();
      let articleListStickyAd = document.getElementById('articleList.stickyAd');
      let textArticleStickyAd = document.getElementById('textArticle.stickyAd');
      articleListStickyAd?.setAttribute('class', 'hidden');
      textArticleStickyAd?.setAttribute('class', 'hidden');

      if (excludeAds || !adType) {
        return;
      }
      window?.googletag?.pubads?.()?.setTargeting('Refresh', '0');
      window.adHandler.service.setTargeting('pfFloorRule', getPfFloorRule());
      window.adHandler.service.setTargeting('pfTag', tags || []);
      window.adHandler.service.setTargeting('pfArticle', articleId || '');
      window.adHandler.service.setTargeting('pfUrl', window.location.href);
      window.adHandler.service.setTargeting(
        'pfSessionPI',
        `${window.pfSessionPI}`,
      );
      window.adHandler.service.setTargeting(
        'pfScreenWidth',
        getPfScreenWidth(),
      );
      window.adHandler.service.setTargeting(
        'pfScreenWidth1290',
        getPfScreenWidth1290(),
      );

      if (adType === 'list') {
        articleListStickyAd?.setAttribute('class', 'flex');
        window.adHandler.mapping_list();
      } else if (adType === 'ta') {
        textArticleStickyAd?.setAttribute('class', 'flex');
        window.adHandler.mapping_ta();
      }

      //Enable Interstital after or in 2. PI Session
      if (!mobileinterstitialEnabled && window.pfSessionPI > 1) {
        window.adHandler.service
          .getAdUnitsByTag('mobileinterstitial')
          .enable('adUnit');
        window.adHandler.service.request();
        mobileinterstitialEnabled = true;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId, tags, excludeAds, adType, ...effectDependencies]);

  useEffect(() => {
    if (!excludeCMPBanner) {
      let acceptCookies;

      try {
        acceptCookies = window.localStorage.getItem('aboUntil');
      } catch (error) {}

      if (acceptCookies) {
        const showCookieBanner = new Date() < new Date(acceptCookies);
        return;
      }

      asyncLoad('gdpr-tcfv2.sp-prod.net/wrapperMessagingWithoutDetection.js');
    }
  }, [excludeCMPBanner]);

  return null;
}

function getPfFloorRule() {
  const rnd = Math.random();
  return (rnd >= 0.1 ? 0 : Math.floor(rnd * 100) + 1) + '';
}

function getPfScreenWidth() {
  const windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return windowWidth + '';
}

function getPfScreenWidth1290() {
  const windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return windowWidth >= 1290 ? 'yes' : 'no';
}
